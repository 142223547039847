import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import Cookies from 'js-cookie';

import { UserStore } from '@/store/modules/user';
import { getBookmarks, getClaims } from '@/firebase/models/user';

const firebaseConfig = {
  apiKey: 'AIzaSyAKncBLIq1exQGdF09jGdvRbsixFKNIY60',
  authDomain: 'est-databases.firebaseapp.com',
  databaseURL: 'https://est-databases.firebaseio.com',
  projectId: 'est-databases',
  storageBucket: 'est-databases.appspot.com',
  messagingSenderId: '966982689956',
  appId: '1:966982689956:web:c38be54ab51f04030a7231',
};

firebase.initializeApp(firebaseConfig);

const fire = firebase;
const auth = firebase.auth();
const db = firebase.firestore();
const functions = firebase.functions();
const storage = firebase.storage();

// db.enablePersistence().then(() => console.log('Offline Support Enabled.'));

if (process.env.NODE_ENV === 'development') {
  functions.useFunctionsEmulator('http://localhost:8080');
}

const usersCollection = db.collection('Users');
const dailyCollection = db.collection('DailyQuestion');
const overviewCollection = db.collection('Overview');
const lessonCollection = db.collection('LessonQA');
const practiceCollection = db.collection('PracticeExam');
const targetedCollection = db.collection('TargetedReview');
const tfCollection = db.collection('TrueFalse');
const lfCollection = db.collection('LearningFeature');

auth.onAuthStateChanged(async (user: any) => {
  if (user) {
    UserStore.setUser(user);
    if (Cookies.getJSON('customClaims')) {
      UserStore.setClaims(Cookies.getJSON('customClaims'));
    } else {
      const userClaims: any = await getClaims();
      UserStore.setClaims(userClaims);
    }
    const bookmarks = await getBookmarks();
    UserStore.setBookmarks(bookmarks);
  } else {
    if (UserStore.authenticated) {
      await UserStore.logout();
    }
  }
});

export {
  fire,
  db,
  auth,
  functions,
  storage,
  usersCollection,
  dailyCollection,
  overviewCollection,
  lessonCollection,
  practiceCollection,
  targetedCollection,
  tfCollection,
  lfCollection,
};
