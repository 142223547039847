




























import {Component, Vue, Prop} from 'vue-property-decorator';

@Component
export default class Hamburger extends Vue {
  @Prop({default: false}) isActive!: boolean;
  @Prop({default: undefined}) toggleClick!: () => void;
}
