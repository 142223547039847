/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'check': {
    width: 24,
    height: 28,
    viewBox: '0 0 24 28',
    data: '<path pid="0" d="M18.297 12.703l-6.594 6.594a.99.99 0 0 1-1.406 0l-4.594-4.594a.99.99 0 0 1 0-1.406l1.594-1.594a.99.99 0 0 1 1.406 0L11 14l4.297-4.297a.99.99 0 0 1 1.406 0l1.594 1.594a.99.99 0 0 1 0 1.406zM20.5 14c0-4.688-3.813-8.5-8.5-8.5S3.5 9.313 3.5 14s3.813 8.5 8.5 8.5 8.5-3.813 8.5-8.5zm3.5 0c0 6.625-5.375 12-12 12S0 20.625 0 14 5.375 2 12 2s12 5.375 12 12z"/>'
  }
})
