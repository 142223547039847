import {Vue} from 'vue-property-decorator';

Vue.filter('truncate', (text: string, length: number, type: string = 'word', clamp: string = '...') => {
  let tcText: string | string[] = text;

  if (type === 'word') {
    tcText = text.split(' ');
    if (tcText.length > length) {
      tcText = tcText.slice(0, length);
      tcText.push(clamp);
      if (text.startsWith('<p>')) {
        tcText.push('</p>');
      }
    }
  } else {
    if (tcText.length > length) {
      tcText = tcText.slice(0, length);
      tcText += clamp;
      if (text.startsWith('<p>')) {
        tcText += ('</p>');
      }
    }
  }

  return type === 'word' ? (typeof tcText !== 'string' ? tcText.join(' ') : '') : tcText;
});
