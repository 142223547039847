/* tslint:disable */
import './book'
import './check'
import './crosshairs'
import './dashboard'
import './eye-open'
import './eye'
import './info'
import './input-checked'
import './layers'
import './link'
import './list'
import './password'
import './pie-chart'
import './plus'
import './puzzle-piece'
import './question'
import './radio-button'
import './search'
import './shuffle'
import './signal'
import './target'
import './user'
import './word'
