






































import Breadcrumb from '@/components/Breadcrumb/index.vue';
import Hamburger from '@/components/Hamburger/index.vue';
import GravatarImg from './Gravatar.vue';
import {Component, Vue} from 'vue-property-decorator';
import {AppModule} from '@/store/modules/app';
import {UserStore} from '@/store/modules/user';

@Component({
  components: {
    Breadcrumb,
    Hamburger,
    GravatarImg,
  },
})
export default class Navbar extends Vue {
  get sidebar() {
    return AppModule.sidebar;
  }

  get email() {
    return UserStore.email;
  }

  toggleSideBar() {
    AppModule.ToggleSideBar(false);
  }

  logout() {
    UserStore.logout().then(() => {
      location.reload(); // in order to avoid vue-router bug
    });
  }
}
