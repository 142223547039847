/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'list': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M4 20.5v3c0 .266-.234.5-.5.5h-3a.514.514 0 0 1-.5-.5v-3c0-.266.234-.5.5-.5h3c.266 0 .5.234.5.5zm0-6v3c0 .266-.234.5-.5.5h-3a.514.514 0 0 1-.5-.5v-3c0-.266.234-.5.5-.5h3c.266 0 .5.234.5.5zm0-6v3c0 .266-.234.5-.5.5h-3a.514.514 0 0 1-.5-.5v-3c0-.266.234-.5.5-.5h3c.266 0 .5.234.5.5zm24 12v3c0 .266-.234.5-.5.5h-21a.514.514 0 0 1-.5-.5v-3c0-.266.234-.5.5-.5h21c.266 0 .5.234.5.5zM4 2.5v3c0 .266-.234.5-.5.5h-3a.514.514 0 0 1-.5-.5v-3c0-.266.234-.5.5-.5h3c.266 0 .5.234.5.5zm24 12v3c0 .266-.234.5-.5.5h-21a.514.514 0 0 1-.5-.5v-3c0-.266.234-.5.5-.5h21c.266 0 .5.234.5.5zm0-6v3c0 .266-.234.5-.5.5h-21a.514.514 0 0 1-.5-.5v-3c0-.266.234-.5.5-.5h21c.266 0 .5.234.5.5zm0-6v3c0 .266-.234.5-.5.5h-21a.514.514 0 0 1-.5-.5v-3c0-.266.234-.5.5-.5h21c.266 0 .5.234.5.5z"/>'
  }
})
