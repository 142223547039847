import NProgress from 'nprogress';
import {Route} from 'vue-router';
import 'nprogress/nprogress.css';

import router from './router';
import {UserStore} from '@/store/modules/user';
import {Can} from '@/plugins/can';
import {Message} from 'element-ui';

router.beforeEach((to: Route, from: Route, next: any) => {
  let requiresAuth;
  let action;
  let subject;
  to.matched.forEach((record) => {
    requiresAuth = record.meta.requiresAuth;
    action = record.meta.action;
    subject = record.meta.subject;
  });
  NProgress.start();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!UserStore.authenticated) {
      next(`/login?redirect=${to.path}`); // otherwise redirect to login page
      NProgress.done();
    } else if (UserStore.authenticated && to.path === '/login') {
      next({path: to.query.redirect || '/dashboard'});
    } else if (UserStore.authenticated && subject && action) {
      if (Can(action, subject)) {
        next();
      } else {
        Message({
          type: 'error',
          message: 'You do not have the proper permissions for this action',
        });
        next({path: '/dashboard'});
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach(() => NProgress.done());
