












import {Component, Vue, Watch} from 'vue-property-decorator';
import {RouteRecord} from 'vue-router';

@Component
export default class Breadcrumb extends Vue {
  levelList: RouteRecord[] = [];

  created() {
    this.getBreadcrumb();
  }

  @Watch('$route')
  onRouteChange() {
    this.getBreadcrumb();
  }

  getBreadcrumb() {
    let matched = this.$route.matched.filter((item) => item.name);
    const first = matched[0];
    if (first && first.name !== 'dashboard') {
      matched = [{path: '/dashboard', meta: {title: 'Questions Database'}} as RouteRecord].concat(matched);
    }
    this.levelList = matched;
  }
}
