import Vue from 'vue';
import Vuex, {Payload, Store} from 'vuex';

import {IAppState} from './modules/app';
import {UserState} from './modules/user';

Vue.use(Vuex);

export interface IRootState {
  app: IAppState;
  user: UserState;
}

// Declare empty store first, dynamically register all http later.
export default new Vuex.Store<IRootState>({
  // plugins: [vuexCookie.plugin, vuexLocal.plugin],
});
