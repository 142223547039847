import {Action, getModule, Module, Mutation, MutationAction, VuexModule} from 'vuex-module-decorators';
import Cookies from 'js-cookie';

import store from '@/store';
import router from '@/router';

import {auth, fire, usersCollection} from '@/firebase';
import {Message} from 'element-ui';
import {addBookmark, getClaims, removeBookmark} from '@/firebase/models/user';

export interface UserState {
  bookmarks: any | {};
  claims: any | {};
  id: string | undefined;
  name: string | undefined;
  email: string | undefined;
  token: string | undefined;
}

@Module({dynamic: true, store, name: 'user'})
class UserModule extends VuexModule implements UserState {
  bookmarks: any = {};
  claims: any = {};
  id = '';
  name = '';
  email = '';
  token = '';

  get authenticated() {
    return !!this.token;
  }

  @Mutation
  setUser(userInfo: { uid: string, displayName: string, email: string, _lat: string; }) {
    this.id = userInfo.uid;
    this.name = userInfo.displayName;
    this.name = userInfo.email;
    this.token = userInfo._lat;
  }

  @Mutation
  setBookmarks(bookmarks: object) {
    this.bookmarks = bookmarks;
  }

  @Mutation
  setClaims(claims: object) {
    this.claims = claims;
  }

  @Action({commit: 'setBookmarks'})
  async addBookmark(item: { collection: string, id: string, question: string }) {
    return await addBookmark(item);
  }

  @Action({commit: 'setBookmarks'})
  async removeBookmark(id: string) {
    return await removeBookmark(id);
  }

  @MutationAction({mutate: ['id', 'name', 'email', 'token', 'bookmarks', 'claims']})
  async login(credentials: { email: string, password: string }) {
    await auth.setPersistence(fire.auth.Auth.Persistence.LOCAL);
    return auth.signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(async (user: any) => {
        const userRef = await usersCollection.doc(user.user.uid).get();
        const userClaims: any = await getClaims();
        const userData: any = userRef.data();
        return {
          id: user.user.uid,
          name: user.user.displayName,
          email: user.user.email,
          token: user.user._lat,
          bookmarks: userData.bookmarks,
          claims: userClaims,
        };
      })
      .catch((error) => {
        Message({
          type: 'error',
          message: error.message,
        });
        Cookies.remove('customClaims');
        return {
          id: undefined,
          name: undefined,
          email: undefined,
          token: undefined,
          bookmarks: undefined,
          claims: undefined,
        };
      });
  }

  @MutationAction({mutate: ['bookmarks', 'claims', 'id', 'name', 'email', 'token']})
  async logout() {
    await auth.signOut()
      .then(() => router.push('/login'))
      .catch((error) => console.error(error));
    Cookies.remove('customClaims');
    return {
      bookmarks: {},
      claims: undefined,
      id: undefined,
      name: undefined,
      email: undefined,
      token: undefined,
    };
  }


  @MutationAction({mutate: ['name', 'email']})
  async updateProfile(email: string, name: string) {
    if (auth.currentUser) {
      await auth.currentUser.updateEmail(email)
        .then(() => {
          Message({
            type: 'success',
            message: 'Email address successfully updated',
          });
        })
        .catch((error) => {
          Message({
            type: 'error',
            message: 'Something went wrong, please try again later',
          });
          return {
            name: this.name,
            email: this.email,
          };
        });
      await auth.currentUser.sendEmailVerification();
      await auth.currentUser.updateProfile({
          displayName: name,
          photoURL: '',
        })
        .catch((error) => {
          Message({
            type: 'error',
            message: 'Something went wrong, please try again later',
          });
          return {
            name: this.name,
            email: this.email,
          };
        });
      return {
        name,
        email,
      };
    } else {
      Message({
        type: 'error',
        message: 'Something went wrong, please try again later',
      });
      return {
        name: this.name,
        email: this.email,
      };
    }

  }

}

export const UserStore = getModule(UserModule);
