/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'layers': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M30.765 7.262L19.222.747c-1.571-.887-4.271-.888-5.845 0L1.834 7.262C.951 7.761.466 8.459.466 9.229s.486 1.468 1.368 1.966l11.543 6.516c.786.444 1.824.688 2.922.688s2.137-.245 2.923-.688l11.543-6.516c.883-.498 1.369-1.196 1.369-1.966s-.487-1.468-1.369-1.967zm-.526 3.003l-11.543 6.516c-1.238.699-3.556.7-4.794 0L2.359 10.265c-.524-.296-.824-.674-.824-1.036s.3-.74.824-1.037l11.543-6.516c.619-.35 1.493-.55 2.396-.55.904 0 1.778.201 2.397.55l11.543 6.516c.524.296.825.674.825 1.037.001.362-.299.74-.824 1.036zm.526 3.834a.536.536 0 0 0-.526.931c.524.296.825.674.825 1.036s-.301.74-.825 1.036l-11.543 6.516c-1.298.733-3.494.734-4.794 0L2.359 17.102c-.524-.296-.824-.674-.824-1.036s.3-.74.824-1.036a.534.534 0 1 0-.526-.931C.95 14.597.465 15.296.465 16.066s.485 1.469 1.368 1.967l11.543 6.516c.819.462 1.871.694 2.922.694s2.104-.231 2.923-.694l11.543-6.516c.883-.498 1.369-1.197 1.369-1.967s-.486-1.469-1.368-1.967zm0 6.705a.536.536 0 0 0-.526.931c.524.296.825.674.825 1.036s-.301.74-.825 1.037l-11.543 6.516c-1.298.733-3.494.734-4.794 0L2.359 23.807c-.524-.296-.824-.674-.824-1.037 0-.362.3-.74.824-1.036a.534.534 0 1 0-.526-.931C.952 21.302.466 22 .466 22.771s.485 1.469 1.368 1.967l11.543 6.516c.819.462 1.871.694 2.922.694 1.052 0 2.104-.231 2.923-.694l11.543-6.516c.883-.499 1.369-1.197 1.369-1.967s-.487-1.469-1.369-1.967z"/>'
  }
})
