import Vue from 'vue';

import 'normalize.css';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';

import SvgIcon from 'vue-svgicon';
import '@/styles/index.scss';

import '@/icons';
import '@/routerPermission';
import '@/registerServiceWorker';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

import Can from '@/plugins/can';
import {auth} from '@/firebase';

import '@/filters/prettyDate';
import '@/filters/truncate';

import * as Sentry from '@sentry/browser';
Sentry.init({dsn: 'https://947ad83117144d5895df93cc9d918fb1@sentry.io/1537284'});

Vue.use(ElementUI, {locale});
Vue.use(Can);

Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em',
});

Vue.config.productionTip = false;

let app: any = false;

auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
