/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'puzzle-piece': {
    width: 26,
    height: 28,
    viewBox: '0 0 26 28',
    data: '<path pid="0" d="M26 17.156c0 1.609-.922 2.953-2.625 2.953-1.906 0-2.406-1.734-4.125-1.734-1.25 0-1.719.781-1.719 1.937 0 1.219.5 2.391.484 3.594v.078c-.172 0-.344 0-.516.016-1.609.156-3.234.469-4.859.469-1.109 0-2.266-.438-2.266-1.719 0-1.719 1.734-2.219 1.734-4.125 0-1.703-1.344-2.625-2.953-2.625-1.641 0-3.156.906-3.156 2.703 0 1.984 1.516 2.844 1.516 3.922 0 .547-.344 1.031-.719 1.391-.484.453-1.172.547-1.828.547-1.281 0-2.562-.172-3.828-.375-.281-.047-.578-.078-.859-.125l-.203-.031C.047 24.016 0 24.016 0 24.001v-16c.063.047.984.156 1.141.187 1.266.203 2.547.375 3.828.375.656 0 1.344-.094 1.828-.547.375-.359.719-.844.719-1.391C7.516 5.547 6 4.688 6 2.703 6 .906 7.516 0 9.172 0c1.594 0 2.938.922 2.938 2.625 0 1.906-1.734 2.406-1.734 4.125 0 1.281 1.156 1.719 2.266 1.719 1.797 0 3.578-.406 5.359-.5V8c-.047.063-.156.984-.187 1.141-.203 1.266-.375 2.547-.375 3.828 0 .656.094 1.344.547 1.828.359.375.844.719 1.391.719 1.078 0 1.937-1.516 3.922-1.516 1.797 0 2.703 1.516 2.703 3.156z"/>'
  }
})
