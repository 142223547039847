



































import path from 'path';
import {Route} from 'vue-router';
import {validateURL} from '@/utils/validate';
import {Component, Vue, Prop} from 'vue-property-decorator';
import Item from './Item.vue';
import AppLink from './Link.vue';

@Component({
  // Set 'name' here to prevent uglifyjs from causing recursive component not work
  // See https://medium.com/haiiro-io/element-component-name-with-vue-class-component-f3b435656561 for detail
  name: 'SidebarItem',
  components: {Item, AppLink},
})
export default class SidebarItem extends Vue {
  @Prop({required: true}) item!: Route;
  @Prop({default: false}) isNest!: boolean;
  @Prop({default: ''}) basePath!: string;

  onlyOneChild: Route | undefined = undefined;

  hasOneShowingChild(children: Route[]) {
    if (!children) {
      return false;
    }
    const showingChildren = children.filter((item: Route) => {
      if (item.meta && item.meta.hidden) {
        return false;
      } else {
        this.onlyOneChild = item; // This will only be used if hasOneShowingChild return true
        return true;
      }
    });
    return showingChildren.length === 1;
  }

  resolvePath(routePath: string) {
    if (this.isExternalLink(routePath)) {
      return routePath;
    }
    return path.resolve(this.basePath, routePath);
  }

  isExternalLink(routePath: string) {
    return validateURL(routePath);
  }
}
