import {auth, usersCollection} from '@/firebase';
import Cookies from 'js-cookie';

const addBookmark = async (bookmark: { collection: string, id: string, question: string }) => {
  if (auth.currentUser) {
    const userRef = await usersCollection.doc(auth.currentUser.uid).get();
    const userData: any = userRef.data();
    userData.bookmarks[bookmark.id] = bookmark;
    await usersCollection.doc(auth.currentUser.uid).set(userData);
    return userData.bookmarks;
  }
  return {};
};

const removeBookmark = async (id: string) => {
  if (auth.currentUser) {
    const userRef = await usersCollection.doc(auth.currentUser.uid).get();
    const userData: any = userRef.data();
    delete userData.bookmarks[id];
    await usersCollection.doc(auth.currentUser.uid).set(userData);
    return userData.bookmarks;
  }
  return {};
};

const getBookmarks = async () => {
  if (auth.currentUser) {
    const userRef = await usersCollection.doc(auth.currentUser.uid).get();
    const userData: any = userRef.data();
    if (userData) {
      return userData.bookmarks;
    }
  }
  return {};
};

const getClaims = async () => {
  if (auth.currentUser) {
    const idTokenResult = await auth.currentUser.getIdTokenResult();
    Cookies.set('customClaims', idTokenResult.claims);
    return idTokenResult.claims;
  }
};

export {addBookmark, removeBookmark, getBookmarks, getClaims};
