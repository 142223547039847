/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'word': {
    width: 24,
    height: 28,
    viewBox: '0 0 24 28',
    data: '<path pid="0" d="M22.937 5.938c.578.578 1.062 1.734 1.062 2.562v18a1.5 1.5 0 0 1-1.5 1.5h-21a1.5 1.5 0 0 1-1.5-1.5v-25a1.5 1.5 0 0 1 1.5-1.5h14c.828 0 1.984.484 2.562 1.062zM16 2.125V8h5.875c-.094-.266-.234-.531-.344-.641L16.64 2.468c-.109-.109-.375-.25-.641-.344zM22 26V10h-6.5A1.5 1.5 0 0 1 14 8.5V2H2v24h20zM3.641 12v1.672h1.094L7.297 24h2.484l2-7.578c.078-.234.125-.469.156-.719.016-.125.031-.25.031-.375h.063l.047.375c.047.219.063.469.141.719l2 7.578h2.484l2.562-10.328h1.094V12h-4.688v1.672h1.406l-1.547 6.844c-.063.25-.094.516-.109.719l-.031.328h-.063c0-.094-.031-.219-.047-.328-.047-.203-.078-.469-.141-.719L12.889 12h-1.781l-2.25 8.516c-.063.25-.078.516-.125.719l-.063.328h-.063l-.031-.328a4.58 4.58 0 0 0-.109-.719L6.92 13.672h1.406V12H3.638z"/>'
  }
})
