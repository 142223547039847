/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'crosshairs': {
    width: 24,
    height: 28,
    viewBox: '0 0 24 28',
    data: '<path pid="0" d="M18.703 16H17c-.547 0-1-.453-1-1v-2c0-.547.453-1 1-1h1.703A7.026 7.026 0 0 0 14 7.297V9c0 .547-.453 1-1 1h-2c-.547 0-1-.453-1-1V7.297A7.026 7.026 0 0 0 5.297 12H7c.547 0 1 .453 1 1v2c0 .547-.453 1-1 1H5.297A7.026 7.026 0 0 0 10 20.703V19c0-.547.453-1 1-1h2c.547 0 1 .453 1 1v1.703A7.026 7.026 0 0 0 18.703 16zM24 13v2c0 .547-.453 1-1 1h-2.234A9.009 9.009 0 0 1 14 22.766V25c0 .547-.453 1-1 1h-2c-.547 0-1-.453-1-1v-2.234A9.009 9.009 0 0 1 3.234 16H1c-.547 0-1-.453-1-1v-2c0-.547.453-1 1-1h2.234A9.009 9.009 0 0 1 10 5.234V3c0-.547.453-1 1-1h2c.547 0 1 .453 1 1v2.234C17.359 6 20 8.64 20.766 12H23c.547 0 1 .453 1 1z"/>'
  }
})
