









import { Component, Vue, Prop } from 'vue-property-decorator';
import { validateURL } from '@/utils/validate';

@Component
export default class Link extends Vue {
  @Prop({ required: true }) to!: string;

  isExternalLink(routePath: string) {
    return validateURL(routePath);
  }
}
