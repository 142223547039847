




import {Component, Prop, Vue} from 'vue-property-decorator';
import md5 from 'md5';

@Component({
  name: 'gravatar-img',
})
export default class GravatarImg extends Vue {

  @Prop({required: true}) email!: string;
  @Prop({default: 80}) size!: number;
  @Prop({default: ''}) customClass!: string;
  @Prop({default: 'mm'}) default!: string;
  @Prop({default: ''}) alt!: string;

  get img() {
    const url = [];
    url.push('//www.gravatar.com/avatar/');
    url.push(md5(this.email.trim().toLowerCase()));
    url.push(`?s=${this.size}`);
    url.push(`&d=${this.default}`);
    return url.join('');
  }

}
