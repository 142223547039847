/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'target': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M17.94 11H13V9h4.94c-.469-3.628-3.311-6.471-6.9-6.936L11 2.06V7H9V2.06c-3.628.469-6.471 3.311-6.936 6.9L2.06 9H7v2H2.06c.469 3.628 3.311 6.471 6.9 6.936l.04.004V13h2v4.94c3.628-.469 6.471-3.311 6.936-6.9l.004-.04zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"/>'
  }
})
