










import Vue from 'vue';

export default class AppMain extends Vue {}
