
















import {Component, Vue} from 'vue-property-decorator';
import {AppModule} from '@/store/modules/app';
import SidebarItem from './SidebarItem.vue';

@Component({
  components: {
    SidebarItem,
  },
})
export default class SideBar extends Vue {
  get sidebar() {
    return AppModule.sidebar;
  }

  get routes() {
    return (this.$router as any).options.routes;
  }

  get isCollapse() {
    return !this.sidebar.opened;
  }
}
