/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'book': {
    width: 26,
    height: 28,
    viewBox: '0 0 26 28',
    data: '<path pid="0" d="M25.609 7.469c.391.562.5 1.297.281 2.016l-4.297 14.156C21.202 24.969 19.827 26 18.484 26H4.062C2.468 26 .765 24.734.187 23.109c-.25-.703-.25-1.391-.031-1.984.031-.313.094-.625.109-1 .016-.25-.125-.453-.094-.641.063-.375.391-.641.641-1.062.469-.781 1-2.047 1.172-2.859.078-.297-.078-.641 0-.906.078-.297.375-.516.531-.797.422-.719.969-2.109 1.047-2.844.031-.328-.125-.688-.031-.938.109-.359.453-.516.688-.828.375-.516 1-2 1.094-2.828.031-.266-.125-.531-.078-.812.063-.297.438-.609.688-.969.656-.969.781-3.109 2.766-2.547l-.016.047C8.939 2.078 9.204 2 9.47 2h11.891c.734 0 1.391.328 1.781.875.406.562.5 1.297.281 2.031l-4.281 14.156C18.408 21.468 18.001 22 16.017 22H2.439c-.203 0-.453.047-.594.234-.125.187-.141.328-.016.672C2.142 23.812 3.22 24 4.079 24h14.422c.578 0 1.25-.328 1.422-.891l4.688-15.422c.094-.297.094-.609.078-.891.359.141.688.359.922.672zM8.984 7.5c-.094.281.063.5.344.5h9.5c.266 0 .562-.219.656-.5l.328-1c.094-.281-.063-.5-.344-.5h-9.5c-.266 0-.562.219-.656.5zm-1.296 4c-.094.281.063.5.344.5h9.5c.266 0 .562-.219.656-.5l.328-1c.094-.281-.063-.5-.344-.5h-9.5c-.266 0-.562.219-.656.5z"/>'
  }
})
