import {Vue} from 'vue-property-decorator';
import moment from 'moment';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;

Vue.filter('prettyDate', (value: Timestamp) => {
  let date;
  if (value) {
    try {
      date = value.toDate();
    } catch (e) {
      date = value;
    }
    return moment(date).format('MMMM D, YYYY, HH:mm');
  }
  return '?';
});
