/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'question': {
    width: 24,
    height: 28,
    viewBox: '0 0 24 28',
    data: '<path pid="0" d="M13.75 18.75v2.5c0 .281-.219.5-.5.5h-2.5a.494.494 0 0 1-.5-.5v-2.5c0-.281.219-.5.5-.5h2.5c.281 0 .5.219.5.5zm4-7.75c0 2.219-1.547 3.094-2.688 3.734-.812.469-1.313.766-1.313 1.266v.5c0 .281-.219.5-.5.5h-2.5a.494.494 0 0 1-.5-.5v-1.062c0-1.922 1.375-2.531 2.484-3.031.938-.438 1.516-.734 1.516-1.437 0-.906-1.141-1.578-2.172-1.578-.547 0-1.125.172-1.484.422-.344.234-.672.578-1.25 1.297a.474.474 0 0 1-.391.187.479.479 0 0 1-.297-.094L6.968 9.923a.507.507 0 0 1-.109-.672c1.281-2.016 3.078-3 5.453-3 2.562 0 5.437 2.031 5.437 4.75zM12 4C6.484 4 2 8.484 2 14s4.484 10 10 10 10-4.484 10-10S17.516 4 12 4zm12 10c0 6.625-5.375 12-12 12S0 20.625 0 14 5.375 2 12 2s12 5.375 12 12z"/>'
  }
})
