/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'pie-chart': {
    width: 27,
    height: 28,
    viewBox: '0 0 27 28',
    data: '<path pid="0" d="M12 13.906l8.531 8.531A11.957 11.957 0 0 1 12 26C5.375 26 0 20.625 0 14S5.375 2 12 2v11.906zm2.922.094H27c0 3.328-1.359 6.359-3.563 8.531zM26 12H14V0c6.625 0 12 5.375 12 12z"/>'
  }
})
