











import { Navbar, AppMain, Sidebar } from './components';
import ResizeMixin from './mixin/ResizeHandler';
import { Component, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { DeviceType, AppModule } from '@/store/modules/app';

@Component({
  components: {
    Navbar,
    Sidebar,
    AppMain,
  },
})
export default class Layout extends mixins(ResizeMixin) {
  get classObj() {
    return {
      hideSidebar: !this.sidebar.opened,
      openSidebar: this.sidebar.opened,
      withoutAnimation: this.sidebar.withoutAnimation,
      mobile: this.device === DeviceType.Mobile,
    };
  }

  handleClickOutside() {
    AppModule.CloseSideBar(false);
  }
}
