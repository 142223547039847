import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/views/layout/Layout.vue';

Vue.use(Router);

/*
  redirect: if `redirect: noredirect`, it won't redirect if click on the breadcrumb
  meta: {
    title: 'title' the name showed in submenu and breadcrumb (recommend set)
    icon: 'svg-name' the icon showed in the sidebar
    hidden: true if `hidden: true`, this route will not show in the sidebar (default is false)
    alwaysShow: true if set to true, it will always show the root menu
    if not set, only show with nested mode if there are more than one route under its children
  }
*/

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({x: 0, y: 0}),
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'Login',
      meta: {title: 'Login', collection: 'Login'},
      component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
    },
    {
      path: '/',
      name: 'Root',
      redirect: '/dashboard',
      meta: {title: 'Question Databases', collection: 'QuestionDatabase'},
    },
    {
      path: '/dashboard',
      component: Layout,
      meta: {
        title: 'Dashboard',
        requiresAuth: true,
      },
      children: [{
        path: '',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index.vue'),
        meta: {
          title: 'Dashboard',
          icon: 'dashboard',
          requiresAuth: true,
        },
      }],
    },
    {
      path: '/daily-question',
      component: Layout,
      meta: {
        title: 'Daily Question',
        requiresAuth: true,
        subject: 'DailyQuestion',
        action: 'view',
      },
      children: [{
        path: '',
        name: 'DailyQuestion',
        component: () => import(/* webpackChunkName: "daily" */ '@/views/daily/index.vue'),
        meta: {
          title: 'Daily Question',
          icon: 'question',
          requiresAuth: true,
        },
      }],
    },
    {
      path: '/search',
      component: Layout,
      meta: {
        title: 'Search',
        requiresAuth: true,
      },
      children: [{
        path: '',
        name: 'Search',
        component: () => import(/* webpackChunkName: "search" */ '@/views/search/index.vue'),
        meta: {
          title: 'Search',
          icon: 'search',
          subject: 'Search',
          action: 'view',
        },
      }],
    },
    {
      path: '/lesson-qa',
      component: Layout,
      name: 'Lesson Q&A',
      meta: {
        title: 'Lesson Q&A',
        icon: 'book',
        class: 'LessonQA',
        requiresAuth: true,
      },
      children: [
        {
          path: 'overview',
          name: 'LessonOverview',
          component: () => import(/* webpackChunkName: "lesson-overview" */ '@/views/lesson/index.vue'),
          meta: {
            title: 'Overview',
            icon: 'list',
            requiresAuth: true,
            subject: 'LessonQA',
            action: 'view',
          },
        },
        {
          path: 'create',
          name: 'LessonCreate',
          component: () => import(/* webpackChunkName: "lesson-create" */ '@/views/lesson/form.vue'),
          meta: {
            title: 'Create',
            icon: 'plus',
            requiresAuth: true,
            subject: 'LessonQA',
            action: 'create',
          },
        },
        {
          path: 'edit/:id',
          name: 'LessonEdit',
          component: () => import(/* webpackChunkName: "lesson-edit" */ '@/views/lesson/form.vue'),
          meta: {
            title: 'Edit', icon: 'tree', hidden: true,
            requiresAuth: true,
            subject: 'LessonQA',
            action: 'update',
          },
        },
        {
          path: 'export',
          name: 'LessonExport',
          component: () => import(/* webpackChunkName: "lesson-export" */ '@/views/lesson/export.vue'),
          meta: {
            title: 'Export', icon: 'word',
            requiresAuth: true,
            subject: 'LessonQA',
            action: 'export',
          },
        },
      ],
    },
    {
      path: '/practice-exam',
      name: 'Practice Exam',
      component: Layout,
      redirect: '/practice-exam/overview',
      meta: {
        title: 'Practice Exam',
        icon: 'pie-chart',
        class: 'PracticeExam',
        subject: 'PracticeExam',
        action: 'view',
      },
      children: [
        {
          path: 'overview',
          name: 'PracticeExamOverview',
          component: () => import(/* webpackChunkName: "exam-overview" */ '@/views/exam/index.vue'),
          meta: {
            title: 'Overview',
            icon: 'list',
            requiresAuth: true,
            subject: 'PracticeExam',
            action: 'view',
          },
        },
        {
          path: 'create',
          name: 'PracticeExamCreate',
          component: () => import(/* webpackChunkName: "exam-create" */ '@/views/exam/form.vue'),
          meta: {
            title: 'Create',
            icon: 'plus',
            requiresAuth: true,
            subject: 'PracticeExam',
            action: 'create',
          },
        },
        {
          path: 'edit/:id',
          name: 'PracticeExamEdit',
          component: () => import(/* webpackChunkName: "exam-edit" */ '@/views/exam/form.vue'),
          meta: {
            title: 'Edit',
            hidden: true,
            requiresAuth: true,
            subject: 'PracticeExam',
            action: 'update',
          },
        },
        {
          path: 'export',
          name: 'PracticeExamExport',
          component: () => import(/* webpackChunkName: "exam-export" */ '@/views/exam/export.vue'),
          meta: {
            title: 'Export',
            icon: 'word',
            requiresAuth: true,
            subject: 'PracticeExam',
            action: 'export',
          },
        },
      ],
    },
    {
      path: '/targeted-review',
      name: 'Targeted Review',
      component: Layout,
      redirect: '/targeted-review/overview',
      meta: {
        title: 'Targeted Review',
        icon: 'target',
        class: 'TargetedReview',
        subject: 'TargetedReview',
        action: 'view',
      },
      children: [
        {
          path: 'overview',
          name: 'TargetedReviewOverview',
          component: () => import(/* webpackChunkName: "targeted-overview" */ '@/views/targeted/index.vue'),
          meta: {
            title: 'Overview',
            icon: 'list',
            requiresAuth: true,
            subject: 'TargetedReview',
            action: 'view',
          },
        },
        {
          path: 'create',
          name: 'TargetedReviewCreate',
          component: () => import(/* webpackChunkName: "targeted-create" */ '@/views/targeted/form.vue'),
          meta: {
            title: 'Create',
            icon: 'plus',
            requiresAuth: true,
            subject: 'TargetedReview',
            action: 'create',
          },
        },
        {
          path: 'edit/:id',
          name: 'TargetedReviewEdit',
          component: () => import(/* webpackChunkName: "targeted-edit" */ '@/views/targeted/form.vue'),
          meta: {
            title: 'Edit',
            hidden: true,
            requiresAuth: true,
            subject: 'TargetedReview',
            action: 'update',
          },
        },
        {
          path: 'export',
          name: 'TargetedReviewExport',
          component: () => import(/* webpackChunkName: "targeted-export" */ '@/views/targeted/export.vue'),
          meta: {
            title: 'Export',
            icon: 'word',
            requiresAuth: true,
            subject: 'TargetedReview',
            action: 'export',
          },
        },
      ],
    },
    {
      path: '/true-false',
      name: 'True/False',
      component: Layout,
      redirect: '/true-false/overview',
      meta: {
        title: 'True/False',
        icon: 'radio-button',
        class: 'TrueFalse',
        subject: 'TrueFalse',
        action: 'view',
      },
      children: [
        {
          path: 'overview',
          name: 'TrueFalseOverview',
          component: () => import(/* webpackChunkName: "tf-overview" */ '@/views/true-false/index.vue'),
          meta: {
            title: 'Overview',
            icon: 'list',
            requiresAuth: true,
            subject: 'TrueFalse',
            action: 'view',
          },
        },
        {
          path: 'create',
          name: 'TrueFalseCreate',
          component: () => import(/* webpackChunkName: "tf-create" */ '@/views/true-false/form.vue'),
          meta: {
            title: 'Create',
            icon: 'plus',
            requiresAuth: true,
            subject: 'TrueFalse',
            action: 'create',
          },
        },
        {
          path: 'edit/:id',
          name: 'TrueFalseEdit',
          component: () => import(/* webpackChunkName: "tf-edit" */ '@/views/true-false/form.vue'),
          meta: {
            title: 'Edit',
            hidden: true,
            requiresAuth: true,
            subject: 'TrueFalse',
            action: 'update',
          },
        },
        {
          path: 'export',
          name: 'TrueFalseExport',
          component: () => import(/* webpackChunkName: "tf-export" */ '@/views/true-false/export.vue'),
          meta: {
            title: 'Export',
            icon: 'word',
            requiresAuth: true,
            subject: 'TrueFalse',
            action: 'export',
          },
        },
      ],
    },
    {
      path: '/learning-feature',
      component: Layout,
      name: 'LearningFeature',
      redirect: '/learning-feature/overview',
      meta: {
        title: 'Learning Feature',
        icon: 'puzzle-piece',
        class: 'LearningFeature',
        subject: 'LearningFeature',
        action: 'view',
      },
      children: [
        {
          path: 'overview',
          name: 'LearningFeatureOverview',
          component: () => import(/* webpackChunkName: "lf-overview" */ '@/views/learning-feature/index.vue'),
          meta: {
            title: 'Overview',
            icon: 'list',
            requiresAuth: true,
            subject: 'LearningFeature',
            action: 'view',
          },
        },
        {
          path: 'create',
          name: 'LearningFeatureCreate',
          component: () => import(/* webpackChunkName: "lf-create" */ '@/views/learning-feature/form.vue'),
          meta: {
            title: 'Create',
            icon: 'plus',
            requiresAuth: true,
            subject: 'LearningFeature',
            action: 'create',
          },
        },
        {
          path: 'edit/:id',
          name: 'LearningFeatureEdit',
          component: () => import(/* webpackChunkName: "lf-edit" */ '@/views/learning-feature/form.vue'),
          meta: {
            title: 'Edit',
            hidden: true,
            requiresAuth: true,
            subject: 'LearningFeature',
            action: 'update',
          },
        },
        {
          path: 'export',
          name: 'LearningFeatureExport',
          component: () => import(/* webpackChunkName: "lf-export" */ '@/views/learning-feature/export.vue'),
          meta: {
            title: 'Export',
            icon: 'word',
            requiresAuth: true,
            subject: 'LearningFeature',
            action: 'export',
          },
        },
        {
          path: 'configuration',
          name: 'LearningFeatureConfiguration',
          component: () => import(/* webpackChunkName: "lf-config" */ '@/views/learning-feature/configuration.vue'),
          meta: {
            title: 'Configuration',
            icon: 'info',
            requiresAuth: true,
            subject: 'LearningFeature',
            action: 'configure',
          },
        },
        {
          path: 'builder',
          name: 'LearningFeatureExamBuilder',
          component: () => import(/* webpackChunkName: "lf-builder" */ '@/views/learning-feature/builder.vue'),
          meta: {
            title: 'Exam Builder',
            icon: 'layers',
            requiresAuth: true,
            subject: 'LearningFeature',
            action: 'builder',
          },
        },
        {
          path: 'builder/review',
          name: 'LearningFeatureExamReview',
          component: () => import(/* webpackChunkName: "lf-builder" */ '@/views/learning-feature/review.vue'),
          meta: {
            title: 'Exam Review',
            icon: 'layers',
            requiresAuth: true,
            subject: 'LearningFeature',
            action: 'builder',
          },
        },
      ],
    },
    {
      path: '/users',
      component: Layout,
      redirect: '/users/list',
      meta: {
        title: 'Users',
        icon: 'example',
        hidden: true,
        requiresAuth: true,
        subject: 'Users',
        action: 'view',
      },
      children: [
        {
          path: 'list',
          name: 'UsersList',
          component: () => import(/* webpackChunkName: "users" */ '@/views/users/index.vue'),
          meta: {
            title: 'List',
            icon: 'example',
            requiresAuth: true,
            subject: 'Users',
            action: 'view',
          },
        },
      ],
    },
    {
      path: '/user',
      component: Layout,
      redirect: '/user/bookmarks',
      meta: {title: 'User', icon: 'example', hidden: true},
      children: [
        {
          path: 'bookmarks',
          name: 'Bookmarks',
          component: () => import(/* webpackChunkName: "bookmark" */ '@/views/user/bookmark.vue'),
          meta: {
            title: 'Bookmarks',
            icon: 'example',
            requiresAuth: true,
            subject: 'Bookmarks',
            action: 'view',
          },
        },
        {
          path: 'profile',
          name: 'Profile',
          component: () => import(/* webpackChunkName: "profile" */ '@/views/user/profile.vue'),
          meta: {
            title: 'Profile',
            icon: 'example',
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/trash',
      component: Layout,
      meta: {
        title: 'Trash',
        hidden: true,
        subject: 'Trash',
        action: 'view',
      },
      children: [
        {
          path: '/',
          name: 'Trash',
          component: () => import(/* webpackChunkName: "trash" */ '@/views/trash/index.vue'),
          meta: {
            title: 'Trash',
            icon: 'trash',
            requiresAuth: true,
            subject: 'Trash',
            action: 'view',
          },
        },
      ],
    },
    {
      path: '/exports',
      component: Layout,
      meta: {
        title: 'Exports',
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'Exports',
          component: () => import(/* webpackChunkName: "trash" */ '@/views/exports/index.vue'),
          meta: {
            title: 'Exports',
            icon: 'layers',
            requiresAuth: true,
            subject: 'Exports',
            action: 'view',
          },
        },
      ],
    },
    {
      path: '/utilities',
      component: Layout,
      meta: {
        title: 'Utilities',
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: 'Utilities',
          component: () => import(/* webpackChunkName: "utilities" */ '@/views/utilities/index.vue'),
          meta: {
            title: 'Utilities',
            icon: 'layers',
            requiresAuth: true,
            subject: 'Utilities',
            action: 'view',
          },
        },
      ],
    },
    {
      path: '/404',
      name: 'Not Found',
      meta: {title: 'NotFound'},
      component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    },
    {path: '*', meta: {title: '*'}, component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue')},
  ],
});
