/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'info': {
    width: 10,
    height: 28,
    viewBox: '0 0 10 28',
    data: '<path pid="0" d="M10 21v2c0 .547-.453 1-1 1H1c-.547 0-1-.453-1-1v-2c0-.547.453-1 1-1h1v-6H1c-.547 0-1-.453-1-1v-2c0-.547.453-1 1-1h6c.547 0 1 .453 1 1v9h1c.547 0 1 .453 1 1zM8 3v3c0 .547-.453 1-1 1H3c-.547 0-1-.453-1-1V3c0-.547.453-1 1-1h4c.547 0 1 .453 1 1z"/>'
  }
})
