/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'plus': {
    width: 22,
    height: 28,
    viewBox: '0 0 22 28',
    data: '<path pid="0" d="M22 11.5v3a1.5 1.5 0 0 1-1.5 1.5H14v6.5a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 8 22.5V16H1.5A1.5 1.5 0 0 1 0 14.5v-3A1.5 1.5 0 0 1 1.5 10H8V3.5A1.5 1.5 0 0 1 9.5 2h3A1.5 1.5 0 0 1 14 3.5V10h6.5a1.5 1.5 0 0 1 1.5 1.5z"/>'
  }
})
