/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dashboard': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M6 18c0-1.109-.891-2-2-2s-2 .891-2 2 .891 2 2 2 2-.891 2-2zm3-7c0-1.109-.891-2-2-2s-2 .891-2 2 .891 2 2 2 2-.891 2-2zm6.687 7.516l1.578-5.969a1.017 1.017 0 0 0-.719-1.219 1.004 1.004 0 0 0-1.219.719l-1.578 5.969a2.991 2.991 0 0 0-2.656 2.219 3.013 3.013 0 0 0 2.141 3.672 3.013 3.013 0 0 0 3.672-2.141 3.03 3.03 0 0 0-1.219-3.25zM26 18c0-1.109-.891-2-2-2s-2 .891-2 2 .891 2 2 2 2-.891 2-2zM16 8c0-1.109-.891-2-2-2s-2 .891-2 2 .891 2 2 2 2-.891 2-2zm7 3c0-1.109-.891-2-2-2s-2 .891-2 2 .891 2 2 2 2-.891 2-2zm5 7c0 2.688-.766 5.281-2.203 7.547a1.01 1.01 0 0 1-.844.453H3.047c-.344 0-.656-.172-.844-.453A13.997 13.997 0 0 1 0 18C0 10.281 6.281 4 14 4s14 6.281 14 14z"/>'
  }
})
