import {UserStore} from '@/store/modules/user';

export default {

  // The install method will be called with the Vue constructor as the first argument, along with possible options
  install(Vue: { prototype: { $can: (action: string, subject: string) => boolean; }; directive: (arg0: string, arg1: (el: any, bindings: any, vnode: any) => void) => void; }) {

    function commentNode(el: any, vnode: any) {
      const comment = document.createComment(' ');

      Object.defineProperty(comment, 'setAttribute', {
        value: () => undefined,
      });

      vnode.text = ' ';
      vnode.elm = comment;
      vnode.isComment = true;
      vnode.context = undefined;
      vnode.tag = undefined;
      vnode.data.directives = undefined;

      if (vnode.componentInstance) {
        vnode.componentInstance.$el = comment;
      }

      if (el.parentNode) {
        el.parentNode.replaceChild(comment, el);
      }
    }

    // Add $plugin instance method directly to Vue components
    Vue.prototype.$can = (action: string, subject: string) => Can(action, subject);

    Vue.directive('can', (el: any, bindings: any, vnode: any) => {
      const behavior = bindings.modifiers.disabled ? 'disable' : 'hide';
      if (!UserStore.claims[bindings.value].includes(bindings.arg) && !UserStore.claims.Admin) {
        if (behavior === 'hide') {
          commentNode(el, vnode);
        } else if (behavior === 'disable') {
          el.disabled = true;
        }
      }
    });
  },
};

export function Can(action: string, subject: string) {
  if (UserStore.claims.Admin) {
    return true;
  }

  if (action && subject) {
    if (UserStore.claims[subject] && Array.isArray(UserStore.claims[subject])) {
      return !!UserStore.claims[subject].includes(action);
    } else {
      return false;
    }
  }
  return true;
}
