/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shuffle': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M15.093 6.694h.92v2.862L20 5.532l-3.988-4.025v2.387h-.92c-3.694 0-5.776 2.738-7.614 5.152-1.652 2.172-3.08 4.049-5.386 4.049H0v2.799h2.093c3.694 0 5.776-2.736 7.614-5.152 1.652-2.173 3.08-4.048 5.386-4.048zM5.41 8.458c.158-.203.316-.412.477-.623a47.33 47.33 0 0 1 1.252-1.596C5.817 5.005 4.224 4.095 2.093 4.095H0v2.799h2.093c1.327 0 2.362.623 3.317 1.564zm10.602 4.836h-.92c-1.407 0-2.487-.701-3.491-1.738l-.303.397c-.441.58-.915 1.201-1.439 1.818 1.356 1.324 3 2.324 5.232 2.324h.92v2.398L20 14.468l-3.988-4.025v2.851z"/>'
  }
})
