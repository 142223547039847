/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'signal': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M4 22.5v3c0 .281-.219.5-.5.5h-3a.494.494 0 0 1-.5-.5v-3c0-.281.219-.5.5-.5h3c.281 0 .5.219.5.5zm6-2v5c0 .281-.219.5-.5.5h-3a.494.494 0 0 1-.5-.5v-5c0-.281.219-.5.5-.5h3c.281 0 .5.219.5.5zm6-4v9c0 .281-.219.5-.5.5h-3a.494.494 0 0 1-.5-.5v-9c0-.281.219-.5.5-.5h3c.281 0 .5.219.5.5zm6-6v15c0 .281-.219.5-.5.5h-3a.494.494 0 0 1-.5-.5v-15c0-.281.219-.5.5-.5h3c.281 0 .5.219.5.5zm6-8v23c0 .281-.219.5-.5.5h-3a.494.494 0 0 1-.5-.5v-23c0-.281.219-.5.5-.5h3c.281 0 .5.219.5.5z"/>'
  }
})
